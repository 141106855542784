<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="importquery">
        <div class="box">
            <div class="nei">
                <el-calendar @click.native='aaa' ref="aaa">
                    <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                    <template
                        slot="dateCell"
                        slot-scope="{date, data}"
                        >
                        <div class="text">{{ data.day.split('-').slice(2).join('') }}
                            <div v-for="(item,index) in rqi_list" :key="index">
                                <span class="lvse" v-if="data.day==item"></span>
                            </div>
                        </div>
                    </template>
                </el-calendar>
            </div>
            <!-- <div class="box2">
                <div class="tit2">
                    <span>时间</span>
                    <span>导入</span>
                    <span>错误信息</span>
                </div>
                <ul>
                    <li v-if="wushuju"><p style="width:100%;text-align:center;">暂无导入数据</p></li>
                    <li v-for="(i,index) in shuju_list" :key='index'>
                        <p style="width:46%">{{i.operate_time}}</p><p style="width:50%;">{{i.title}}</p><p style="width:13%;">{{i.remark}}</p>
                    </li>
                </ul>
            </div> -->
            <table class="box2">
                <tr>
                    <td>时间</td>
                    <td>导入</td>
                    <td>信息</td>
                    <td>成功条数</td>
                    <td>错误信息</td>
                </tr>
                <p v-if="shuju_list.length==0" style="width:100%;text-align:center;height:0.45rem;line-height:0.45rem;padding-bottom:0.62rem;">暂无数据</p>
                <tr v-for="(i,index) in shuju_list" :key="index">
                    <td>{{i.operate_time}}</td>
                    <td>{{i.daoru}}</td>
                    <td>{{i.title}}</td>
                    <td>{{i.hang}}</td>
                    <td>{{i.remark}}</td>
                </tr>
            </table>
        </div>
        <el-pagination
            style="margin-left:13%;"
            @current-change="dianji_yeshu"
            :current-page="dangqian_yeshu"
            class="fenye_qi"
            layout="total, prev, pager, next, jumper"
            :total="dangqian_zongshu">
        </el-pagination>
    </div>
</template>

<script>
import { query_import_log_date_list, query_import_log_list } from '../../api/api.js'
export default {
  name: 'importquery',
  data () {
    return {
      ent_id: '',
      sj: null,
      calendarData: [],
      shuju_list: [],
      wushuju: true,
            
      dangqian_yeshu: 1, // 当前页数
      dangqian_zongshu: 0, // 当前总数

      rqi_list: []// 日期列表

    }
  },
  mounted () {
    console.log(this.$refs.aaa)
    this.jinyue()
    this.jichu()
  },
  created () {
    if (sessionStorage.getItem('pc_mlbb_ent_info') != null && sessionStorage.getItem('pc_mlbb_ent_info') != undefined && sessionStorage.getItem('pc_mlbb_ent_info') != '') {
      this.ent_id = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_id
    }
  },
  watch: {
    shuju_list () {
      if (this.shuju_list.length != 0) {
        this.wushuju = false
      } else {
        this.wushuju = true
      }
    },
    dangqian_yeshu () {
      this.jiekou()
    }
  },
  methods: {
    // 时间小于10前面加0
    pa (s) {
      return s < 10 ? '0' + s : s
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    // 获取本月时间
    jinyue () {
      var xian_sj = new Date()
      this.sj = xian_sj.getFullYear() + '-' + this.pa(xian_sj.getMonth() + 1) + '-' + this.pa(xian_sj.getDate())
      console.log(6666, this.sj)
    },
    //   基础信息
    jichu () {
      query_import_log_date_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date, 9900000)
          this.rqi_list = date
        } else if (res.data.code == 500) {}
      })
      this.jiekou()
    },
    jiekou () {
      const sj = this.$refs.aaa.formatedDate // .split('-').join('') //点击的日期
      // console.log(this.$refs.aaa)
      console.log(sj)
      query_import_log_list({
        data: {
          ent_id: this.ent_id,
          aim: null,
          operate_time: sj,
          type: null,
          page_size: '10',
          page_index: this.dangqian_yeshu + ''
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10221) {
          const date = JSON.parse(res.data.body.data)
          // console.log(date)
          // if(date.length!=0){
          //     this.wushuju=false
          //     for(let i=0;i<date.length;i++){
          //         // 
          //         if(date[i].aim==1){
          //             date[i]['daoru']='产品'
          //         }else if(date[i].aim==2){
          //             date[i]['daoru']='科目'
          //         }else if(date[i].aim==3){
          //             date[i]['daoru']='客户'
          //         }else if(date[i].aim==4){
          //             date[i]['daoru']='员工'
          //         }else if(date[i].aim==5){
          //             date[i]['daoru']='漏斗'
          //         }else if(date[i].aim==6){
          //             date[i]['daoru']='合同'
          //         }else if(date[i].aim==7){
          //             date[i]['daoru']='发票'
          //         }else if(date[i].aim==8){
          //             date[i]['daoru']='回款'
          //         }else if(date[i].aim==9){
          //             date[i]['daoru']='供应商产品报价单'
          //         }else if(date[i].aim==10){
          //             date[i]['daoru']='供应商录入单'
          //         }
          //         date[i]['hang']=date[i].number!=undefined?date[i].number+'行':''
          //     }
          //     this.shuju_list=date
          // }else{
          //     this.wushuju=true
          //     this.shuju_list=[]
          // }
          if (date.list.length != 0) {
            this.dangqian_zongshu = date.total
            this.wushuju = false
            for (let i = 0; i < date.list.length; i++) {
              // 
              if (date.list[i].aim == 1) {
                date.list[i].daoru = '产品'
              } else if (date.list[i].aim == 2) {
                date.list[i].daoru = '科目'
              } else if (date.list[i].aim == 3) {
                date.list[i].daoru = '客户'
              } else if (date.list[i].aim == 4) {
                date.list[i].daoru = '员工'
              } else if (date.list[i].aim == 5) {
                date.list[i].daoru = '漏斗'
              } else if (date.list[i].aim == 6) {
                date.list[i].daoru = '合同'
              } else if (date.list[i].aim == 7) {
                date.list[i].daoru = '发票'
              } else if (date.list[i].aim == 8) {
                date.list[i].daoru = '回款'
              } else if (date.list[i].aim == 9) {
                date.list[i].daoru = '供应商产品报价单'
              } else if (date.list[i].aim == 10) {
                date.list[i].daoru = '供应商录入单'
              }
              date.list[i].hang = date.list[i].number != undefined ? date.list[i].number + '行' : ''
            }
            this.shuju_list = date.list
            // this.kanban_neirong_list=date.list
          } else {
            this.wushuju = true
            this.dangqian_zongshu = 0
            this.shuju_list = []
            // this.kanban_neirong_list=[]
          }
        } else if (res.data.code == 10222) {
        }
      })
    },
    aaa () {
      this.jiekou()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.importquery {
    height:100%;
    width:100%;
    .box{
        .nei{
            width:8.4rem;
            margin: 0.3rem auto;
            .text{
                position: relative;
                text-align: right;
                span{
                    position: absolute;
                    height:0.1rem;
                    width:0.1rem;
                    border-radius: 50%;
                    top: 330%;
                    left:0;
                    background: #52C41A;
                }
            }
        }
        .box2{
            width: 84%;
            margin: 0 auto;
            margin-bottom:0.2rem;
                display: block;
                tr{
                    min-height:0.45rem;
                    display: flex;
                    font-size: 0.18rem;
                    td{
                        display: flex;
                        align-items: center;
                        border-bottom: 0.01rem solid #eee;
                        width: 25%;
                        justify-content: center;
                        color:#1A2533;
                    }
                    &:nth-child(1){
                        background: #F3F3F3;
                        border:none;
                    }
                }
            // .tit2{
            //     height:0.45rem;
            //     background: #F3F3F3;
            //     display: flex;
            //     align-items: center;
            //     padding: 0 1rem;
            //     justify-content: space-between;
            //     span{
            //         color:#1A2533;
            //         font-size: 0.18rem;
            //     }
            // }
            // ul{
            //     min-height: 1.66rem;
            //     li{
            //         padding: 0.3rem 1rem;
            //         display: flex;
            //         border-bottom: 0.01rem solid #eee;
            //         &:hover{
            //             background: rgb(250, 248, 248);
            //         }
            //         p{
            //             font-size: 0.14rem;
            //             color:#4C4A4D;
            //         }
            //     }
            // }
        }
    }
}
</style>
